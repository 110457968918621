/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "sa-east-1",
    "aws_cognito_identity_pool_id": "sa-east-1:d05c40ba-3d2e-41f8-97f7-85960da3f7b5",
    "aws_cognito_region": "sa-east-1",
    "aws_user_pools_id": "sa-east-1_QqPQ13J8R",
    "aws_user_pools_web_client_id": "7fos330qf4eqqhktnod5k0oovp",
    "oauth": {}
};


export default awsmobile;
