import React, { useEffect, useState } from "react";
import { Avatar, makeStyles, TextField } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import ApiRequest from "../requests/automationApi";
import TextComponent from "../components/Text";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  imageAvatar: {
    display: "flex",
  },
  csName: {
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));

const customerSuccessList = [
  {
    name: 'Alcides Freitas',
    nickname: 'Alcides',
    email: 'alcides.borges@franq.com.br',
    number: '48984324935',
    bio: 'Com passagens por bancos como Santander, Caixa e Corporate e Atacado do Banco do Brasil, estou na Franq desde março de 2020. Serei seu Gerente de Sucesso.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLVANUtgreSLnN-azrZqc2QpjVDlNooFrwE8V6if6jLzdJ6tYm1oVrrG5H6X7GZI7Lz3DwAyq_U7I4zAuX9JSDveMfpnI7VZQj1bsPFW06lbEyTKKJPQCDcpzDYTGy6F8QtbPKv9npWuJMSwgUFdHY3a=s300-no?authuser=0'
  },
  {
    name: 'Bruna Melo',
    nickname: 'Bruna',
    email: 'bruna.melo@franq.com.br',
    number: '48984855119',
    bio: 'Com mais de 8 anos de experiência bancária, serei sua Gerente de Sucesso na sua jornada como Personal Banker aqui na Franq.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLWdw4dCWLEZOrEQEczKhVy1q_PBF5Pz2BuSsPbySeDh_vCVTwC_Joj_zX3pvEZvint4orm8Ff3PgYUYysWKjxgKw7_1DQAFHc0K_tzzdeqSSh5fK4jw5MxipC_Wgzs69kUfekSod8sLVdW_6FO4SZ81=s300-no?authuser=0'
  },
  {
    name: 'Camila Casagrande',
    nickname: 'Camila',
    email: 'camila.casagrande@franq.com.br',
    number: '48984210659',
    bio: 'Possuo experiência na área comercial do mercado financeiro, e serei sua Gerente de Sucesso como Personal Banker aqui na Franq! Conte comigo para te ajudar nesse novo desafio!',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLUb891_g6qzme6K_r_beJrPn89-DqNPC2dK_RSUhZVZV1m9TfluD4hEooTA3ANy_-qzompIA8VMc9mT_JYOGcDKKRXCZZn8jk3E0AndmxhCGXxY9pNu_rxXnXVNPvb-bWRNu6E5VNq36LfcIS_8FYLX=s300-no?authuser=0'
  },
  {
    name: 'Fernanda Tenente',
    nickname: 'Fernanda',
    email: 'fernanda.tenente@franq.com.br',
    number: '48984668774',
    bio: 'Tenho mais de 20 anos em Instituições Financeiras, comecei na Franq, exatamente no início, como Personal Banker em novembro de 2019. Hoje sou sua Gerente de Sucesso. Conte sempre comigo sempre.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLW6Z6SlXjEE7G3o_lQ6c-zm5Rgyn_87sRhdVNwpLqqfhirq6iYhoULZ9Woa83WcXb7S4yn9G5aIcM4unMhjyIi956lr6FgVOwm2NwSydtFDxqsV3fDkiOBFIn6RfHO-ggWUQ9DeiWqaVYa6HP8GD447=s300-no?authuser=0'
  },
  {
    name: 'Márcio Freire Graff',
    nickname: 'Márcio',
    email: 'marcio.graff@franq.com.br',
    number: '48984588959',
    bio: 'Com passagem pelo Banco Real e Santander, estou na Franq desde setembro de 2020 e serei seu Gerente de Sucesso na sua jornada como Personal Banker.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLU-ywrjgqJ4tSB7u4RqQu3dr78809JCgtSSnWstPCQ-pXaNJzohRYsYFMM9SZOavBgZUUSXaxfWyLBkj6WY0745DwjBuxYd7jjdMWKqAt3NFIOQwbj_0CvSLcwQwzycyliaAVJcr-4X-KevIz8sxyk8=s300-no?authuser=0'
  },
  {
    name: 'Priscila Campos',
    nickname: 'Priscila',
    email: 'priscila.campos@franq.com.br',
    number: '48984163757',
    bio: 'Com mais de 8 anos de experiência na área Financeira, focada no atendimento ao cliente. Serei sua Gerente de Sucesso na sua jornada como Personal Banker.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLXOYO93xEdFKBqnUUlpAIOHPuz7joU7ydQ8yK0llvw8A61wUZ60jDk0OMTwBKtiPmSSjzT0aqE5uZPsG_-7bk55gqqkcF3YsVL63ei9-aWRdixiMNSYmowL-nrRdi7171iFKNwui2GLkLmAxznXKOiu=s300-no?authuser=0'
  },
  {
    name: 'Tais Pazzini',
    nickname: 'Tais',
    email: 'tais.pazzini@franq.com.br',
    number: '48984300124',
    bio: 'Com vasta experiência comercial e no atendimento ao cliente, serei sua Gerente de Sucesso na sua jornada como Personal Banker aqui na Franq.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLWiMPgnoIP7K1wV6Yo_VH_iDSiczx71Jw-TWsPp22eVD7o5SZaAfiVnlFCaaCcMzmOK1ZEgLrdBCnZU-nFRYwN0dCUe6fh3zBe8uPgmBVL9IslNHMTNDgXwRseB5ROoXQQD9FK_6vlRsXTaKe5DM83p=s300-no?authuser=0'
  },
  {
    name: 'Thiago Rodrigues',
    nickname: 'Thiago',
    email: 'thiago.rodrigues@franq.com.br',
    number: '48988104410',
    bio: 'Com mais de 9 anos de experiência comercial em atendimento ao cliente, serei seu Gerente de Sucesso na sua jornada como Personal Banker aqui na Franq.',
    photo: 'https://lh3.googleusercontent.com/pw/AM-JKLXz9vAfZoBRSPAqBJ0RWRxZLlHSKTuyC44Gbb3ytdiOLKKF6m_nhAqndDHWuoItmL2J9tzR87coTbJHdQzFnl6uRz7e6O2sZS3QXaQAfxRWzRgigzCMSoKxrI8JEShHP0No54qnlTekXX_mVle-6wQ0=s300-no?authuser=0'
  },
  {
    name: 'Marcelo Fries',
    nickname: 'Marcelo',
    email: 'marcelo.fries@franq.com.br',
    number: '48984330246',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Amanda Libório',
    nickname: 'Amanda',
    email: 'amanda.liborio@franq.com.br ',
    number: '48985050189',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Thiago Miranda',
    nickname: 'Thiago',
    email: 'thiago.miranda@franq.com.br',
    number: '48984944829',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Sheila Dotto',
    nickname: 'Sheila',
    email: 'sheila.dotto@franq.com.br',
    number: '48984570172',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Henrique Nunes',
    nickname: 'Henrique',
    email: 'henrique.nunes@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Angélica Winckler',
    nickname: 'Angélica',
    email: 'sheila.dotto@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Lucas Camargo',
    nickname: 'Lucas',
    email: 'lucas.camargo@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Thais Baierles',
    nickname: 'Thais',
    email: 'thais.baierles@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Fabio Lodi',
    nickname: 'Fabio',
    email: 'fabio.lodi@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Paula Ramos',
    nickname: 'Paula',
    email: 'paula.ramos@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Nathalia Oliboni',
    nickname: 'Nathalia',
    email: 'nathalia.oliboni@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Carlos Eduardo Dantas Rodrigues',
    nickname: 'Carlos',
    email: 'carlos.rodrigues@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Arthur Martins Raquel',
    nickname: 'Arthur',
    email: 'Arthur',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Janaina Soares Lins',
    nickname: 'Janaina',
    email: 'janaina.lins@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Marli Milane Gonçalves',
    nickname: 'Marli',
    email: 'marli.milane@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Maguidiéli Aparecida Marques',
    nickname: 'Magui',
    email: 'maguidieli.marques@fraanq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Jaime Gonzaga Martins Filho',
    nickname: 'Jaime',
    email: 'jaime.martins@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Monique Oliveira',
    nickname: 'Monique',
    email: 'monique.oliveira@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Mirella lehmkuhl turnes',
    nickname: 'Mirella',
    email: 'mirella.lehmkuhl@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Juliana Abascal de Assis Brasil',
    nickname: 'Juliana',
    email: 'juliana.assis@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Marilei Betker',
    nickname: 'Marilei',
    email: 'marilei.betker@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Leonel Pontes',
    nickname: 'Leonel',
    email: 'leonel.souza@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Aurio Nogueira Santos Filho',
    nickname: 'Aurio',
    email: 'aurio.filho@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Carlos Alberto Negreiros Júnior',
    nickname: 'Carlos Negreiros',
    email: 'carlos.negreiros@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Edimara Nicolodi Brum',
    nickname: 'Edimara',
    email: 'edimara.brum@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Otávio Basso',
    nickname: 'Otávio',
    email: 'otavio.basso@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Tamyres Maciel',
    nickname: 'Tamyres',
    email: 'tamyres.maciel@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Outros - DFImóveis',
    nickname: 'Outros',
    email: 'marcio.graff@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Rogéria Vieira',
    nickname: 'Rogéria',
    email: 'rogeria.vieira@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Carolyne Keren Barbosa Teixeira Maroso',
    nickname: 'Carolyne',
    email: ' carolyne.keren@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Thainá Alves Dalmagro',
    nickname: 'Thainá',
    email: 'thaina.dalmagro@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Mariane Lixinski',
    nickname: 'Mariane',
    email: 'mariane.lixinski@franq.com.br',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Grasiele Pereira',
    nickname: '...',
    email: '...',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Juliana Olivieri',
    nickname: '...',
    email: '...',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Luis Felipe Siebel',
    nickname: '...',
    email: '...',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Tatiane Cristina Garcia',
    nickname: '...',
    email: '...',
    number: '...',
    bio: '...',
    photo: '...'
  },
  {
    name: 'Vanessa Araújo',
    nickname: '...',
    email: '...',
    number: '...',
    bio: '...',
    photo: '...'
  },

]

export const PersonalBanker = ({ match: { params: { pbId } } }) => {
  const apiRequest = ApiRequest();
  const [fetching, setFetching] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pb, setPb] = useState();
  const classes = useStyles();

  const fetchPersonalBanker = () => {
    setFetching(true);

    apiRequest.getPb(pbId)
      .then(pb => setPb(pb))
      .finally(() => setFetching(false))
  };

  const onChangeCs = (customerSuccess) => {
    setLoading(true);
    apiRequest.editPb(pbId, { customerSuccess })
      .then(pb => setPb(pb))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    return (
      fetchPersonalBanker()
    )
  }, [])

  return (
    <div>
      <div className="mt-5">
        {fetching ?
          <div>
            <Skeleton className="rounded" animation="wave" variant="rect" height={100}></Skeleton>
            {[''].map((el, i) =>
              <Skeleton key={i} className="mt-4 rounded" animation="wave" variant="rect" height={60}></Skeleton>
            )}
          </div>
          :
          <div className="d-flex justify-content-center">
            {!pb ?
              <TextComponent type="h5">
                Personal Banker não encontrado
              </TextComponent>
              :
              <div>
                <TextComponent type="h2">
                  {pb.name}
                </TextComponent>

                {loading ?
                  [''].map((el, i) =>
                    <Skeleton key={i} className="mt-5 rounded" animation="wave" variant="rect" height={60}></Skeleton>
                  )
                  :
                  <div className="mt-5 align-items-center">
                    <Autocomplete
                      openOnFocus
                      autoHighlight
                      blurOnSelect
                      options={customerSuccessList}
                      filterOptions={options => options}
                      inputValue={pb.customerSuccess}
                      getOptionLabel={option => option}
                      onChange={(event, value) => onChangeCs(value)}
                      renderOption={option =>
                        <div className={classes.imageAvatar}>
                          <div> <Avatar src={option.photo} /> </div>
                          <div className={classes.csName}> {option.name} </div>
                        </div>
                      }
                      renderInput={params =>
                        <TextField {...params} variant="outlined" label={'Customer Success'} />
                      }
                    />
                  </div>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
};

export default PersonalBanker;
