import Axios from 'axios';

// const { AUTOMATION_API_URL } = process.env;

const AUTOMATION_API_URL = 'https://automacao.franq.com.br/automation'

const headers = {
  'Accept': 'application/json',
  'Content-Type': 'application/json',
  'Authorization': 'franqueza2020'
};

export const automationApi = Axios.create({ baseURL: AUTOMATION_API_URL, headers });
