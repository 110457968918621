import { automationApi } from "../services/requests.service";

const ApiRequest = () => {

    const getBots = () => {
        return new Promise((resolve, reject) => {
            automationApi.get(`/getBots`)//, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    const getLogs = (botName, value) => {
        return new Promise((resolve, reject) => {
            const term = value ? `?term=${value}` : ''
            automationApi.get(`/getLogs/${botName}${term}`)//, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    const getLogsbyDate = (botName, value, firstDateValue, finalDateValue, limitLogs) => {
        return new Promise((resolve, reject) => {
            const term = value ? `term=${value}` : ''
            const dateParams = firstDateValue >= finalDateValue ? '' : `&startTime=${firstDateValue}&endTime=${finalDateValue}`
            const limit = limitLogs === "" ? "" : `&limit=${limitLogs}`
            automationApi.get(`/getLogs/${botName}/?${term}${dateParams}${limit}`)//, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    const replayBot = (botName, payload) => {
        return new Promise((resolve, reject) => {
            automationApi.post(`/replayBot/${botName}`, payload)//, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    const getPb = pbId => {
        return new Promise((resolve, reject) => {
            automationApi.get(`/pb/${pbId}`)//, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    const editPb = (pbId, payload) => {
        return new Promise((resolve, reject) => {
            automationApi.post(`/pb/${pbId}`, payload)//, { headers: { Authorization: `Bearer ${token}` } })
                .then(response => resolve(response.data))
                .catch(error => reject(error));
        })
    }

    return {
        getBots,
        getLogs,
        replayBot,
        getPb,
        editPb,
        getLogsbyDate
    }
}

export default ApiRequest;
