import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Chip, TextField, Button, makeStyles, Typography } from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Highlight from "../components/Highlight";
import ApiRequest from "../requests/automationApi";
import ButtomComponent from "../components/Button";
import TextComponent from "../components/Text";
import SearchIcon from '@material-ui/icons/Search';
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DayjsUtils from "@date-io/dayjs";
import dayjs from "dayjs";

export const LogsComponent = ({ location, history }) => {
  const botName = location.state.botName;
  const [fetching, setFetching] = useState(false);
  const [botLogs, setBotLogs] = useState(null);
  const [term, setTerm] = useState('');
  const [limit, setLimit] = useState('');
  const [unixFirstDate, setUnixFirstDate] = useState(new Date()/1000);
  const [unixLastDate, setUnixLastDate] = useState(new Date()/1000);
  
  const apiRequest = ApiRequest();
  
  const navigateBack = () => {
    history.goBack();
  }
  
  const refreshLogs = () => {
    setFetching(true);
    
    apiRequest.getLogs(botName)
    .then(result => setBotLogs(result.logs))
    .finally(() => setFetching(false));
  };
  
  const memoBotLogs = useMemo(() => botLogs, [botLogs])
  
  const queryLogs = async () => {
    setFetching(true);
    
    const data = await apiRequest.getLogsbyDate(botName, term, unixFirstDate, unixLastDate, limit)
    setBotLogs(data.logs)
    setFetching(false);
  };
  
  const replayBot = payload => {
    setFetching(true);
    
    apiRequest.replayBot(botName, payload)
    .then(() => alert('Bot replayed successfully'))
    .catch(() => alert('Bot could not be replayed'))
    .finally(() => setFetching(false));
  };
  
  useEffect(() => {
    return (
      refreshLogs()
      )
    }, [])
    
    const setTermLog = useCallback((newValue) => {
      setTerm(newValue.target.value)
    }, [term])
    
    const useStyles = makeStyles((theme) => ({
      divDataStyles: {
        display: "flex",
      },
      boxDataStyles: {
        padding: "1em"
      }
    }));
    
    const classes = useStyles();
    
    return (
      <div>
      <div className="d-flex align-items-center justify-content-between">
        <ButtomComponent
          iconButton
          icon={<ArrowBackIcon />}
          tooltip="Atualizar"
          onClick={navigateBack}
          />
        <TextComponent type="h5">
          {botName}
        </TextComponent>
        <div className="d-flex ml-3">
          <ButtomComponent
            iconButton
            icon={<RefreshIcon />}
            tooltip="Refresh"
            onClick={refreshLogs}
          />
        </div>
      </div>
      <div className={classes.divDataStyles}>
          <TextField
            label="Pesquise!"
            value={term}
            onChange={setTermLog}
            style={{ margin: "1em" }}
          />
      <MuiPickersUtilsProvider utils={DayjsUtils}>
        <DatePicker
          className={classes.boxDataStyles}
          label="Data Inicial"
          value={dayjs.unix(unixFirstDate)}
          format="DD/MM/YYYY"
          onChange={firstDate => setUnixFirstDate(firstDate/1000)}
        />
        <DatePicker
          className={classes.boxDataStyles}
          label="Data Final"
          value={dayjs.unix(unixLastDate)}
          format="DD/MM/YYYY"
          onChange={lastDate => setUnixLastDate(lastDate/1000)}
        /> 
      </MuiPickersUtilsProvider>
         <TextField
            label="Limite de Logs"
            value={limit}
            placeholder="20"
            onChange={limit => setLimit(limit.target.value)}
            style={{ margin: "1em" }}
          />
          <Button variant="outlined" color="primary" onClick={queryLogs} style={{ marginTop: "2em", marginBottom: "1em" }}>
            <SearchIcon></SearchIcon>
          </Button>
      </div>
      <div className="mt-5">
        {!fetching && memoBotLogs ?
          (memoBotLogs.length > 0 ?
            memoBotLogs.map(logSet =>
              <div className="mb-5" key={logSet.timestamp}>
                <Accordion>
                  <div className={`p-3 d-flex align-items-center justify-content-between
                    ${logSet.status === 'success' ? 'bg-success' :
                      logSet.status === 'filtred' ? 'bg-secondary' :
                        logSet.status === 'fail' ? 'bg-danger' : ''}`}>
                    <div className="d-flex align-items-center ">
                      <Chip
                        label={`${new Date(logSet.timestamp).toLocaleDateString()} - ${new Date(logSet.timestamp).toLocaleTimeString()}`}
                        className="bg-white"
                      />
                    </div>
                    <TextComponent type={"caption"}>
                      {logSet.result}
                    </TextComponent>
                    {logSet.status !== 'success' &&
                      <ButtomComponent
                        iconButton
                        tooltip="Replay bot"
                        icon={<VideogameAssetIcon style={{ color: 'white' }} />}
                        onClick={() => replayBot(logSet.inputData)}
                      />
                    }
                  </div>
                </Accordion>
                <Accordion>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <div className="align-self-center">Entrada</div>
                  </AccordionSummary>
                  <AccordionDetails className="d-block">
                    <Highlight>{JSON.stringify(logSet.inputData, null, 2)}</Highlight>
                  </AccordionDetails>
                </Accordion>
                {logSet.steps.map(stepLog =>
                  <Accordion key={stepLog.timestamp}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <div className="align-self-center">{stepLog.stepName}</div>
                    </AccordionSummary>
                    <AccordionDetails className="d-block">
                      {stepLog.inputData &&
                        <div>
                          <div className="mb-1">Input</div>
                          <Highlight>{JSON.stringify(stepLog.inputData, null, 2)}</Highlight>
                        </div>
                      }
                      {stepLog.outputData &&
                        <div>
                          <div className="mb-1">Output</div>
                          <Highlight className="p-5">{JSON.stringify(stepLog.outputData, null, 2)}</Highlight>
                        </div>
                      }
                    </AccordionDetails>
                  </Accordion>
                )}
              </div>)
            : <TextComponent className="mt-5 d-flex justify-content-center">
              Esse bot ainda não tem nenhum log!
            </TextComponent>
          ) :
          ['', '', '', ''].map((el, i) => <Skeleton key={i} className="rounded mb-3" animation="wave" variant="rect" height={120}></Skeleton>)
        }
      </div>
    </div>
  );
};

export default memo(LogsComponent);
